import {
  Button,
  CheckBox,
  Drawer,
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  FlexGrid,
  Form,
  FormItemProps,
  RadialButton,
  Typography,
  useFormCheckBox,
  useThemeProvider
} from '@albo-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddressInputs } from '../../common';
import { useConfirm } from '../../components/confirm-dialog';
import { mainCountry, mainCountryStr } from '../../const';
import { useCatalog } from '../../hooks/use-catalog';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { useUserRole } from '../../hooks/use-user-role';
import { useAuth } from '../../providers/auth-provider';
import { useFormsFields } from '../../providers/onboarding-form-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { FormItems } from '../form-items';
import { Info } from '../info';
import { OnBoardingLoader } from '../on-boarding-loader';

const createSelect = (list: any) => {
  return list.map((item: any) => ({
    name: item.value,
    value: item.key,
  }));
}

export type AddPhysicalShareholderProps = {
  uuidForm: string | undefined;
  open: boolean;
  onClose: () => void;
  totalPercentageUsed: number;
  openSnackbar?: (eventName: string) => void;
  isRealOwnerToComplete?: boolean;
};

export const AddPhysicalShareholder = ({
  onClose,
  open,
  uuidForm,
  openSnackbar,
  totalPercentageUsed,
  isRealOwnerToComplete
}: AddPhysicalShareholderProps) => {
  const {
    REQUIRED_FIELD,
    NAME_OR_DENOMINATION,
    BIRTH_PLACE,
    PARTICIPATION_PERCENTAGE,
    CANCEL,
    ADD,
    PHYSICAL_PERSON_WITH_BUSINESS_ACTIVITY,
    ADD_SHAREHOLDER,
    BIRTH_DATE,
    IS_LISTED_ON_THE_STOCK_MARKET,
    DATE_FORMAT,
    UPDATE,
    SHAREHOLDER,
    PATERNAL_LASTNAME,
    NAME,
    YES,
    NO,
    OR,
    CONSTITUTION,
    PERSON_TYPE,
    MORAL_PERSON,
    PHYSICAL_PERSON,
    STOCK_NAME,
    BLACKBOARD_KEY,
    PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED,
    RFC_WITH_HOMOCLAVE,
    THIRTEEN_CHARACTER_ALPHANUMERIC_KEY,
    TWELVE_CHARACTER_ALPHANUMERIC_KEY,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
    PASSPORT,
    INE,
    ...translations
  } = useTranslator();

  const { formFields, findFields } = useFormsFields();

  useEffect(() => {
    findFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }
  const { leadData } = useAuth();
  const { isOpsAnalyst } = useUserRole();
  const { theme } = useThemeProvider();
  const formProps = useOnboardingForm({
    defaultParams: {
      general: {
        phone: { phoneCode: '+52', phoneCountry: '484', phoneCodeDesc: 'Mexico' },
      },
      address: {
        country: mainCountry,
        countryDesc: 'Mexico',
      },
      identificationFile: {
        type: 'ine',
      },
      declarationRealOwner: true,
      isLead: false,
      isRealOwner:  "no" 
    },
    onboardingFormType: 'QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER',
    singleton: false,
    onboardingFormRef: uuidForm,
  });
  const defaultValidations = useDefaultValidations();
  const validators = useValidators();
  const isListedOnTheStockMarket =
    formProps.form.data.general?.stockMarketListing;
  const saveOnboardingFormEvent = formProps.eventName;
  const [countries] = useCatalog('Countries');
  const [states] = useCatalog('States');
  const [activities] = useCatalog('ShareholderActivities');
  const getFormValue = formProps.form.getPropByName;
  const setFormData = formProps.form.setData;
  const formData = formProps.form.data;
  const { setPropByName } = formProps.form;
  const identificationFileType = formProps.form.data.identificationFile?.type;
  const isRealOwner = isRealOwnerToComplete ? 'yes' : formProps.form.data?.isRealOwner;
  const currentPercentage = getFormValue('general.percentageShares');
  const currentPersonType = getFormValue('general.type');
  const currentCountry = getFormValue('general.country');
  const [genders] = useCatalog('Genders');


  
  const isMainCountrySelected = mainCountryStr === currentCountry;

  const totalPercentageLimit = 100;
  const isOverPercentageLimit =
    totalPercentageLimit - totalPercentageUsed - currentPercentage < 0;
  const percentageIsMoreEqualToTwentyFive =
    currentPercentage >= 25 && currentPercentage <= 100;

  const myInfoCheckBox = useFormCheckBox('isLead', formProps.form);
  const declarationRealOwnerCheckBox = useFormCheckBox('declarationRealOwner', formProps.form);
  const isLead = formProps.form.data.isLead;

  const [curpErrorMessage, setCurpErrorMessage] = useState<string | undefined>(undefined)
  const curpHasErrors = curpErrorMessage !== undefined
  const curp = formProps.form.data?.general?.populationid
  const setErrors = formProps.form.setErrors;

  const listedOnTheStockMarketOptions = useMemo(
    () => [
      {
        name: true,
        text: YES,
        value: isListedOnTheStockMarket === true,
      },
      {
        name: false,
        text: NO,
        value: isListedOnTheStockMarket === false,
      },
    ],
    [isListedOnTheStockMarket, YES, NO]
  );

  const personTypeSelect = [
    {
      name: MORAL_PERSON,
      value: 'moral',
    },
    {
      name: PHYSICAL_PERSON,
      value: 'fisica',
    },
  ];

  const coutriesSelect = countries.map((country: any) => ({
    name: country.value,
    value: country.key,
  }));

  const statesSelect = states.map((state: any) => ({
    value: state.key,
    name: state.value,
  }));

  const addressInputs = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', useAddressInputs({
    setFormData: formProps.form.setData,
    postalCode: formProps.form.data.address.zip,
    formData: formProps.form.data
  }))
  const validateCurp = useCallback(
    (value: string) => {
      const notAlphanumeric = validators.notAlphanumeric(value);
      const doNotHaveMinLength = validators.doNotHaveMinLength(value, 18);
      const notValidCurpFormat = validators.notValidCurpFormat(value);

      return notAlphanumeric || doNotHaveMinLength || notValidCurpFormat;
    },
    [validators]
  );

  useEffect(() => {
    curp?.length > 0
      ? setCurpErrorMessage(validateCurp(curp))
      : setCurpErrorMessage(undefined);
  }, [curp, validateCurp]);



  const onSelectOficialDocument = (selectedIndex: number) => {
    setPropByName('identificationFile', {
      data: undefined,
      type: selectedIndex === 0 ? 'ine' : 'passport',
    });
  }
  const onSelectIsRealOwner = (selectedIndex: number) => {
    setPropByName('isRealOwner',
      selectedIndex === 0 ? "yes" : "no",
    );
  }

  const oficialDocuments = useMemo(
    () => [
      {
        name: 'ine',
        text: INE,
        value: identificationFileType === 'ine',
      },
      {
        name: 'passport',
        text: PASSPORT,
        value: identificationFileType === 'passport',
      },
    ],
    [identificationFileType, PASSPORT, INE]
  );
  const oficialDocumentIne: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [
    {
      placeholder: translations.OFICIAL_IDENTIFICATION_FRONT,
      name: 'identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: translations.UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: translations.OFICIAL_IDENTIFICATION_BACK,
      name: 'identificationFile.data.back',
      required: true,
      type: 'fileInput',
      validators: [validators.isEmpty],
    }
  ]);

  const oficialDocumentPassport: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [
    {
      placeholder: translations.OFICIAL_IDENTIFICATION_FRONT,
      name: 'identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: translations.UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    }
  ]);



  const curpInfo = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [
    {
      placeholder: translations.CURP,
      name: 'general.populationid',
      type: 'input',
      inputType: 'alphanumeric',
      toUpperCase: true,
      error: curpHasErrors,
      errorMessage: curpErrorMessage,
      ...defaultValidations.curpValidations(),
      help: {
        description: translations.CURP_HELP_DESCRIPTION,
      },
    }
  ]);
  const realOwnerGeneralInfo: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [

    {
      placeholder: translations.GENDER,
      name: 'general.gender',
      type: 'select',
      items: createSelect(genders),
      required: true,
      validators: [validators.selectIsEmpty],
    },
    ...(isMainCountrySelected ? curpInfo : []),

    {
      placeholder: translations.OCCUPATION,
      name: 'general.occupation',
      type: 'select',
      items: createSelect(activities),
      required: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: translations.EMAIL,
      name: 'general.email',
      type: 'input',
      required: true,
      ...defaultValidations.emailValidations(),
    },
    {
      name: 'general.phone',
      type: 'phone',
      required: true,
      disabled: myInfoCheckBox.value || isLead,
      ...defaultValidations.phoneNumberValidations(),
    },
  ]);

  const personTypeItem: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [
    {
      placeholder: PERSON_TYPE,
      name: 'general.type',
      type: 'select',
      required: true,
      items: personTypeSelect,
      help: {
        description: "La identificación de una persona física se realiza mediante su nombre, mientras que una persona moral se identifica a través de una denominación o razón social, que suele ser el nombre oficial de una empresa.",
        title: "¿Qué es una persona física y moral?"
      },
      validators: [validators.selectIsEmpty],
    },
  ]);

  const stockMarketInfo: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [
    {
      placeholder: STOCK_NAME,
      name: 'general.stockExchange',
      type: 'input',
      required: true,
      ...defaultValidations.stockNameValidations(),
    },
    {
      placeholder: BLACKBOARD_KEY,
      name: 'general.ticker',
      type: 'input',
      required: true,
      ...defaultValidations.blackboardKeyValidations(),
    },
  ]);

  const rfcInfo: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [
    {
      placeholder: RFC_WITH_HOMOCLAVE,
      name: 'general.taxId',
      type: 'input',
      required: true,
      toUpperCase: true,
      ...defaultValidations.rfcWithHomoclaveValidations(),
      help: {
        description: currentPersonType === 'moral' ? TWELVE_CHARACTER_ALPHANUMERIC_KEY : THIRTEEN_CHARACTER_ALPHANUMERIC_KEY,
      },
    },
  ]);

  const selectState: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [
    {
      placeholder: translations.BIRTH_STATE,
      name: 'general.bstate',
      type: 'select',
      inputType: 'text',
      items: statesSelect,
      search: true,
      required: true,
      validators: [validators.selectIsEmpty],
    },
  ]);
  const inputState: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [
    {
      placeholder: translations.BIRTH_STATE,
      name: 'general.bstate',
      type: 'input',
      inputType: 'text',
      required: true,
      validators: [validators.isEmpty],
    },
  ]);

  const shareholderInfo: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [
    {
      placeholder: NAME_OR_DENOMINATION,
      name: 'general.gname',
      type: 'input',
      required: true,
      ...defaultValidations.nameOrDenominationValidations(),
    },
    {
      placeholder: `${BIRTH_DATE} ${OR} ${CONSTITUTION} ${DATE_FORMAT}`,
      name: 'general.bdate',
      type: 'date',
      inputType: 'email',
      required: true,
      validators: [validators.isEmpty, validators.notValidDateFormat],
    },
    {
      placeholder: `${BIRTH_PLACE} ${OR} ${CONSTITUTION}`,
      name: 'general.country',
      type: 'select',
      required: true,
      items: coutriesSelect,
      search: true,
      validators: [validators.selectIsEmpty],
    },
    ...(isMainCountrySelected ? rfcInfo : []),
    {
      placeholder: PARTICIPATION_PERCENTAGE,
      name: 'general.percentageShares',
      type: 'input',
      inputType: 'number',
      suffix: ' %',
      maxLength: 5,
      saveAs: 'number',
      required: true,
      error: currentPercentage > 100,
      errorMessage: PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED,
      validators: [
        validators.isEmpty,
        (value: string) =>
          parseInt(value.toString()) > 100 || isOverPercentageLimit
            ? PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED
            : undefined,
      ],
    },
  ]);


  const fisicaGeneralInputs: FormItemProps<any>[] = formFields('QUICK_WIN_SHAREHOLDER_AND_REAL_OWNER', [
    {
      placeholder: NAME,
      name: 'general.gname',
      type: 'input',
      required: true,
      disabled: myInfoCheckBox.value,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'general.lname',
      type: 'input',
      required: true,
      disabled: myInfoCheckBox.value,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: translations.MATERNAL_LASTNAME,
      name: 'general.slname',
      type: 'input',
      disabled: myInfoCheckBox.value,
      ...defaultValidations.maternalLastNameValidations(),
    },

    {
      placeholder: `${BIRTH_DATE} ${DATE_FORMAT}`,
      name: 'general.bdate',
      type: 'date',
      inputType: 'email',
      required: true,
      validators: [validators.isEmpty, validators.notValidDateFormat],
    },

    {
      placeholder: translations.BIRTH_COUNTRY,
      name: 'general.country',
      type: 'select',
      required: true,
      items: coutriesSelect,
      search: true,
      validators: [validators.selectIsEmpty],
    },


    ...(isMainCountrySelected ? selectState : inputState),

    ...(isMainCountrySelected ? rfcInfo : []),
    {
      placeholder: PARTICIPATION_PERCENTAGE,
      name: 'general.percentageShares',
      type: 'input',
      inputType: 'number',
      suffix: ' %',
      maxLength: 5,
      saveAs: 'number',
      required: true,
      error: currentPercentage > 100,
      errorMessage: PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED,
      validators: [
        validators.isEmpty,
        (value: string ) =>
          parseInt(value.toString()) > 100 || isOverPercentageLimit
            ? PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED
            : undefined,
      ],
    },

  ]);


  const realOwnerAnswer = useMemo(
    () => [
      {
        name: 'isRealOwner',
        text: YES,
        value: isRealOwner === "yes",
      },
      {
        name: 'isRealOwner',
        text: NO,
        value: isRealOwner === "no",
      },
    ],
    [isRealOwner, YES, NO]
  );

  

  function onSelectStockMarketOptions(selectedIndex: number) {
    const stockMarketListing = selectedIndex === 0;

    var dataWithoutStockInfo = { ...formData };
    delete dataWithoutStockInfo.general?.stockExchange;
    delete dataWithoutStockInfo.general?.ticker;

    setFormData((currentData: any) => ({
      ...(stockMarketListing ? currentData : dataWithoutStockInfo),
      general: {
        ...(stockMarketListing ? currentData : dataWithoutStockInfo).general,
        stockMarketListing,
      },
    }));
  }

  function onDrawerAddButtonClick() {
    if (isRealOwner==="yes" || isRealOwnerToComplete ) {
      setPropByName('isRealOwner',"yes");
    }else{
      setPropByName('isRealOwner',"no");
    }
    if (formProps.form.hasErrors) {
      formProps.form.displayErrors();
    } else if (isOpsAnalyst) {
      handleClickSave();
    } else {
      formProps.moveForward();
    }
  }


  useEffect(() => {
    if (saveOnboardingFormEvent) {
      onClose();
      openSnackbar && openSnackbar(saveOnboardingFormEvent);
    }
  }, [saveOnboardingFormEvent, onClose, openSnackbar]);

  useEffect(() => {
    if (isLead) {
      setFormData((current: any) => ({
        ...current,
        general: {
          ...current.general,
          ...leadData.general,
        },
      }));
    } else {
      setFormData((currentData: any) => ({

        ...currentData,
        general: {
          ...currentData.general,
          gname: "",
          lname: "",
          slname: "",
        },
      }));
    }
  }, [isLead, setFormData, leadData]);


  const clearInputs = useCallback(({ isMainCountrySelected, percentageIsMoreEqualToTwentyFive, currentPersonType, isListedOnTheStockMarket, isRealOwner, isLead }: any) => {
    if (currentPersonType === "moral") {
      //clear inputs fisica person
      setFormData((currentData: any) => ({
        ...currentData,
        isLead: false,
        isRealOwner: "no",
        "general": {
          ...currentData.general,
          lname: "",
          slname: undefined,
          bstate: undefined,
          ...(!isMainCountrySelected && { taxId: undefined }),
          gender: undefined,
          populationid: undefined,
          occupation: undefined,
          email: undefined,
          phone: undefined,
          ...(!isListedOnTheStockMarket && { stockExchange: undefined, ticker: undefined })
        },
        "address": {},
        "identificationFile": {
          type: 'ine'
        },
      }))
      setErrors((currentErrors: any) => ({
        ...currentErrors,

        "general.lname": undefined,
        "general.slname": undefined,
        "general.bstate": undefined,
        ...(!isMainCountrySelected && { "general.taxId": undefined }),
        "general.gender": undefined,
        "general.populationid": undefined,
        "general.occupation": undefined,
        "general.email": undefined,
        "general.phone": undefined,
        ...(!isListedOnTheStockMarket && { "general.stockExchange": undefined, "general.ticker": undefined }),

        "address.country": undefined,
        "address.zip": undefined,
        "address.state": undefined,
        "address.town": undefined,
        "address.locality": undefined,
        "address.street": undefined,
        "address.extnum": undefined,
        "address.intnum": undefined,
        "address.reference": undefined,
        "identificationFile.data.front": undefined,
        "identificationFile.data.back": undefined,
      }))
    } else {
      const isRealOwnerOrPercentageIsMoreEqualToTwentyFive = isRealOwner === "yes" || percentageIsMoreEqualToTwentyFive

      //clear inputs moral person
      setFormData((currentData: any) => ({
        ...currentData,
        "general": {
          ...currentData.general,
          ...(!isMainCountrySelected && { taxId: "" }),
          ...(!isMainCountrySelected && { populationid: "",bstate: ""}),
          ...(!isRealOwnerOrPercentageIsMoreEqualToTwentyFive && {
            gender: "",
            populationid: "",
            occupation: "",
            email: "",
          }),
          ...(!isRealOwnerOrPercentageIsMoreEqualToTwentyFive && !isLead && {
            phone: {}
          }),

          stockExchange: "",
          ticker: ""
        },
        ...(
          (!(isMainCountrySelected && percentageIsMoreEqualToTwentyFive) && isRealOwner === "no")
          && {
            "address": {
            },
            "identificationFile": {
              type: 'ine'
            }
          })
        ,
      }))
      setErrors((currentErrors: any) => ({
        ...currentErrors,
        ...(!isMainCountrySelected && { "general.bstate": undefined }),
        ...(!isMainCountrySelected && { "general.taxId": undefined }),
        ...(!isMainCountrySelected && { "general.populationid": undefined }),
        ...(!isRealOwnerOrPercentageIsMoreEqualToTwentyFive && {
          "general.gender": undefined,
          "general.populationid": undefined,
          "general.occupation": undefined,
          "general.email": undefined,
          "general.phone": undefined,
          "general.phone.phone": undefined,
          "general.phone.phoneCountry": undefined,
        }),
        "general.stockExchange": undefined,
        "general.ticker": undefined,
        ...(
          (!(isMainCountrySelected && percentageIsMoreEqualToTwentyFive) && isRealOwner === "no")
          && {
            "address.country": undefined,
            "address.zip": undefined,
            "address.state": undefined,
            "address.town": undefined,
            "address.locality": undefined,
            "address.street": undefined,
            "address.extnum": undefined,
            "address.intnum": undefined,
            "address.reference": undefined,
            "identificationFile.data.front": undefined,
            "identificationFile.data.back": undefined,
          })
      }
      ))

    }

  }, [setFormData, setErrors])

  useEffect(() => {
    if (currentPersonType) {
      clearInputs({ isMainCountrySelected, percentageIsMoreEqualToTwentyFive, currentPersonType, isListedOnTheStockMarket, isRealOwner, isLead })
    }

  }, [isMainCountrySelected, percentageIsMoreEqualToTwentyFive, currentPersonType, isRealOwner, isListedOnTheStockMarket, isLead, clearInputs]);

  useEffect(() => {
    setErrors((currentErrors: any) => ({
      ...currentErrors,
      ...(identificationFileType === 'passport' && { "identificationFile.data.back": undefined })
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificationFileType])


  if (formProps.loadingForm || formProps.movingForward || formProps.saving)
    return (
      <OnBoardingLoader
        isLoading={
          formProps.loadingForm || formProps.movingForward || formProps.saving
        }
      />
    );

  return (
    <Form {...formProps.form} onSubmit={onDrawerAddButtonClick}>
      <Drawer open={open} onClose={onClose} position='right'>
        <DrawerTitle>
          {uuidForm ? `${UPDATE} ${"Accionistas y propietarios reales"}` : "Accionistas y propietarios reales"}
        </DrawerTitle>


        <DrawerContent>
          <FlexGrid
            item
            container
            fullWidth
            spacing={4}
            direction='column'
            style={{ marginBottom: '60px' }}
          >
            <FlexGrid item container fullWidth spacing={4} direction='column'>

              <Info description="Si tu empresa no tiene accionistas, agrega a la persona física que actuará como propietario real o administrador e indica 0% en el porcentaje de participación." />
              <FormItems inputs={
                personTypeItem
              } />
              {
                currentPersonType === 'fisica' &&
                <>
                  <FlexGrid item>
                    <CheckBox {...myInfoCheckBox} text={`Yo soy el ${SHAREHOLDER}`} />
                  </FlexGrid>
                  <FormItems inputs={fisicaGeneralInputs} />
                </>
              }

              {currentPersonType === 'fisica' && !(percentageIsMoreEqualToTwentyFive && !isOverPercentageLimit) &&
                <>
                  <FlexGrid item>
                    <Typography variant='smallTitle'>
                      ¿Eres propietario real de la empresa?
                    </Typography>
                  </FlexGrid>
                  <FlexGrid item container direction='row' spacing={2}>
                    {realOwnerAnswer.map(({ value, text }, index) => (
                      <FlexGrid item>
                        <RadialButton
                          value={value}
                          onInput={() => onSelectIsRealOwner(index)}
                          text={text}
                        />
                      </FlexGrid>
                    ))}
                  </FlexGrid>
                </>
              }

              {currentPersonType === 'fisica' &&
                ((percentageIsMoreEqualToTwentyFive && !isOverPercentageLimit)
                  || isRealOwner === "yes" || isRealOwnerToComplete)
                && (
                  <FlexGrid item container fullWidth spacing={4} direction='column'>
                    <FlexGrid item>
                      <Typography variant='smallTitle'>
                        Información del propietario real
                      </Typography>
                    </FlexGrid>
                    {
                      !percentageIsMoreEqualToTwentyFive && (
                        <>
                          <FlexGrid item>
                            <CheckBox style={{ padding: 10, height: "auto" }} {...declarationRealOwnerCheckBox} text={`Declaro bajo protesta de decir verdad, que ninguna persona física, ya sea directa o indirectamente, posee el 25% o más de las acciones o del capital social de la empresa que represento. Por lo que los verdaderos propietarios son los administradores, quienes están a cargo de la gestión de la sociedad.`} />
                          </FlexGrid>
                        </>
                      )
                    }
                    <FormItems inputs={realOwnerGeneralInfo} />
                    <FlexGrid item>
                      <Typography variant='smallTitle'>
                        Domicilio particular
                      </Typography>
                    </FlexGrid>
                    <FormItems inputs={addressInputs} />

                    <FlexGrid item>
                      <Typography variant='smallTitle'>
                        {translations.SELECT_IDENTIFICATION_TYPE}
                      </Typography>
                    </FlexGrid>
                    <FlexGrid item container direction='row' spacing={2}>
                      {oficialDocuments.map(({ value, text }, index) => (
                        <FlexGrid item>
                          <RadialButton
                            value={value}
                            onInput={() => onSelectOficialDocument(index)}
                            text={text}
                          />
                        </FlexGrid>
                      ))}
                    </FlexGrid>
                    <FlexGrid item>
                      <Info description="Sube las fotos en formato jpg o png, asegúrate de que toda la información en la foto sea legible y esté vigente." />
                    </FlexGrid>
                    <FlexGrid item>
                      <FormItems
                        inputs={
                          identificationFileType === 'ine'
                            ? oficialDocumentIne
                            : oficialDocumentPassport
                        }
                      />
                    </FlexGrid>
                  </FlexGrid>


                )}

            </FlexGrid>

            {currentPersonType === 'moral' && (
              <FlexGrid item container fullWidth spacing={4} direction='column'>
                <FormItems inputs={shareholderInfo} />
              </FlexGrid>
            )}

            {percentageIsMoreEqualToTwentyFive &&
              currentPersonType === 'moral' && (
                <FlexGrid
                  item
                  container
                  fullWidth
                  spacing={4}
                  direction='column'
                >
                  <FlexGrid item>
                    <Typography variant='smallTitle'>
                      {IS_LISTED_ON_THE_STOCK_MARKET}
                    </Typography>
                  </FlexGrid>
                  <FlexGrid item container direction='row' spacing={5}>
                    {listedOnTheStockMarketOptions.map(
                      ({ value, text }, index) => (
                        <FlexGrid item>
                          <RadialButton
                            value={value}
                            onInput={() => onSelectStockMarketOptions(index)}
                            text={text}
                          />
                        </FlexGrid>
                      )
                    )}
                  </FlexGrid>
                  {isListedOnTheStockMarket && (
                    <FlexGrid item>
                      <FormItems inputs={stockMarketInfo} />
                    </FlexGrid>
                  )}
                </FlexGrid>
              )}


          </FlexGrid>
        </DrawerContent>

        <DialogConfirm />

        <DrawerActions>
          <Button onClick={onClose} buttonType='secondary' size='normal'>
            {CANCEL}
          </Button>
          <Button
            onClick={onDrawerAddButtonClick}
            size='normal'
            style={{ marginLeft: theme.spacesPx[2] }}
          >
            {uuidForm || isOpsAnalyst ? UPDATE : ADD}
          </Button>
        </DrawerActions>
      </Drawer>
    </Form>
  );
};
